<template>
  <div class="auth-page">
    <div class="logo" @click="$emit('closeWindow')"></div>

    <div class="autorisation">
      <div class="autorisation__title">{{ authList.authText }}</div>

      <form class="form form_auth" @submit.prevent="loginRequest" ref="formLogin">
        <div class="form__content">
          <div ref="error" class="form__error" :style="{ opacity: errorInfo ? 1 : 0 }">
            <p class="form__error">{{ errorInfo }}</p>
          </div>

          <div ref="email" class="form__row">
            <label class="form__label" for="username">
              <div class="form__label-title">{{ authList.userEmailText }}</div>

              <input
                class="form__input"
                v-model="user.email"
                type="text"
                @click="clearErrorsPage"
                autocomplete="on"
              />
            </label>
          </div>

          <div ref="password" class="form__row">
            <label class="form__label" for="password">
              <div class="form__label-title">{{ authList.passwordText }}</div>

              <input
                class="form__input"
                v-model="user.psw"
                type="password"
                @click="clearErrorsPage"
                autocomplete="on"
              />
            </label>
          </div>

          <!-- <div class="form__row form__row-center">
            <a class="form__link" href="#" @click.prevent>
              {{ authList.forgotPwdLink }}
            </a>
          </div> -->
        </div>

        <div class="form__buttons">
          <input class="form__button" type="submit" name="enter" :value="authList.login" />

          <!-- <a class="form__button" @click="$emit('openAnotherPage')">
            {{ authList.registration }}
          </a> -->
        </div>
      </form>

      <!-- <div class="vk-auth">
        <p class="vk-auth__text">Войти через :</p>
        <a class="vk-auth__container" @click.prevent="requestVKAuth()">
          <img src="/icons/common/vk.png" class="vk-auth__icon" />

        </a>
      </div> -->
    </div>

    <teleport :to="currentTeleportPath" v-if="currentTeleportPath">
      <tooltip-info v-if="tooltipText" :text="tooltipText" class="tooltip"></tooltip-info>
    </teleport>
  </div>
</template>

<script>
// import axios from 'axios';
import { mapActions } from 'vuex';
import { errorList, authList } from '@/assets/js/values';
import TooltipInfo from '@/components/common/tooltip/TooltipInfo.vue';

export default {
  components: {
    TooltipInfo,
  },

  emits: ['closeWindow'],

  data: () => ({
    user: {
      email: '',
      psw: '',
    },

    errorInfo: '',
    tooltipText: '',

    authList,
    errorList,

    currentTeleportPath: null,
  }),
  methods: {
    ...mapActions('auth', ['enterByCRMKey']),
    ...mapActions('vkRequests', ['requestCurrentPage']),

    checkFieldsOnError() {
      const check = true;
      switch (true) {
        case this.user.email.trim() === '':
          this.tooltipText = errorList.emptyString;
          this.currentTeleportPath = this.$refs.email;

          return !check;
        case this.user.email.indexOf('@') === -1:
          this.tooltipText = errorList.emailError;
          this.currentTeleportPath = this.$refs.email;

          return !check;
        case this.user.psw.trim() === '':
          this.tooltipText = errorList.emptyString;
          this.currentTeleportPath = this.$refs.password;

          return !check;
        default:
      }

      return check;
    },

    clearErrorsPage() {
      this.tooltipText = '';
      this.errorInfo = '';
    },

    async loginRequest() {
      if (!this.checkFieldsOnError()) {
        return;
      }

      await this.enterByCRMKey(this.user.psw)
        .then(() => {
          // this.requestCurrentPage();
          this.$router.push({ name: 'mega-admins' });
          // this.$emit('closeWindow');
        })
        .catch((err) => {
          this.errorInfo = err;
          console.log('err login', err);
        });
    },
  },

  unmounted() {},
};
</script>

<style lang="scss" scoped>
.test {
  background: #2b3138;
  color: #fff;

  width: 100px;
  height: 30px;
  display: block;
}

.form__error {
  height: 25px;
  font-size: 0.975rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
  margin-bottom: 10px;
  margin-top: 10px;
}
.auth-page {
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  box-sizing: border-box;
}
.logo {
  background: #05060767;
  width: 100%;
  height: 100%;
  &__icon {
    max-width: 322px;
    width: 250px;
  }
}
.autorisation {
  // position: absolute;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  // margin-top: 100px;

  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  // padding-left: 7rem;
  // padding-right: 7rem;
  --tw-shadow: 0 24px 19px 12px rgba(0, 0, 0, 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  border: 1px solid #3e4751;
  --tw-text-opacity: 1;
  color: rgba(197, 197, 197, var(--tw-text-opacity));
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background: #324f6ecc;
  color: #e1e1e1;
  font-size: 30px;

  // @media (max-width: 560px) {
  //   margin-top: 70px;
  //   padding-top: 2rem;
  //   padding-bottom: 2rem;
  //   padding-left: 2.5rem;
  //   padding-right: 2.5rem;
  // }

  &:hover {
    background: #1a324b;
  }
}

/* form */
.form__title {
  text-align: center;
  font-size: 0.975rem;
  line-height: 1.25rem;
  font-weight: 600;
}
.form_auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 2.5rem;
}
.form__row {
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}
.form__row-center {
  justify-content: center;
}
.form__label-title {
  font-size: 0.975rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #e1e1e1;
}
/* input */
.form__input {
  width: 100%;
  border: 1px solid #3e4751;
  border-radius: 0.375rem;
  background: #f1f1f1;
  margin-top: 0.25rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #324f6e;
  width: 18rem;
}
[type="text"]:focus,
[type="password"]:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  outline: none !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 0.5;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));

  @media (max-width: 560px) {
    width: 100%;
  }
}

input::-moz-placeholder {
  color: #686f77;
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #686f77;
  opacity: 1;
}

input::placeholder,
textarea::placeholder {
  color: #686f77;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}
/* link */
.form__link {
  color: #e1e1e1;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition-duration: 150ms;
}
.form__link:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
/* bottom */
.form__button {
  margin: 0 10px;
  color: #e1e1e1;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter, -webkit-backdrop-filter;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  height: 3rem;
  font-weight: 600;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background: #6b7280;
  cursor: pointer;
}
.form__buttons {
  display: flex;
}
.form__button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  color: #000;
}
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 18px;
  height: 18px;
}
.tooltip {
  position: absolute;

  bottom: -45px;
  left: 90px;
  z-index: 20;
}
.vk-auth {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin: 1.5rem 0 0 0;
  // .vk-auth__title
  &__title {
    font-size: 16px;
  }
  // .vk-auth__container
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #e1e1e1;
    box-sizing: border-box;
    height: auto;
    // padding: 8px;

    text-decoration: none;
    cursor: pointer;
    width: 70px;
    height: 70px;
  }

  // .vk-auth__icon
  &__icon {
    width: 100%;
    height: 100%;
    // margin: 0 15px 0 0;
  }

  // .vk-auth__text
  &__text {
    font-size: 16px;
  }
}
</style>
